<template>
  <div class="memo-page">
    <c-search searchTitle="备忘录名称" valueFormat="yyyyMMdd" @search="memoSearch" placeholder="关键字" :isDate="true"></c-search>

    <div class="flex" style="border-bottom: 1px solid #edf2f2">
      <c-navigation @change="changeNavigation" title="审批状态" :list="approvalState"></c-navigation>
    </div>
    <c-operate>
      <template #right>
        <div class="btn-group">
          <div class="btn-list courtDate" @click="openAddMemo">新增备忘录</div>
        </div>
      </template>
    </c-operate>

    <c-table :data="memoData">
      <!-- <el-table-column align="center" prop="id" label="选择">
        <template #default="{ row }">
          <el-checkbox v-model="row.isListChecked" @change="checkedClient(row)"></el-checkbox>
          {{ row.data }}
        </template>
      </el-table-column> -->
      <el-table-column align="center" prop="title" label="备忘录名称"> </el-table-column>
      <el-table-column align="center" prop="content" label="备忘录明细"> </el-table-column>
      <el-table-column align="center" prop="startime" label="提醒时间" width="180"> </el-table-column>
      <el-table-column align="center" prop="status" label="状态"> </el-table-column>
      <el-table-column align="center" prop="createtime" label="创建时间" width="180"> </el-table-column>
      <el-table-column align="center" label="操作">
        <template #default="{ row }">
          <div class="table-operate">
            <p class="operate-list edit" @click="checkEditMemo(row)">编辑</p>
            <p class="operate-list follow" @click="follow(row)" :class="{ active: row.isListChecked }">删除</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label=""> </el-table-column>
    </c-table>

    <!--  新增备忘录 -->
    <c-dialog v-model="showNewClient" title="新增备忘录" @confirm="addMemo" :mask="true">
      <div class="addMemo">
        <div class="memo memoTitle">
          <p>备忘录名称</p>
          <input type="text" v-model="selectedTable.title" id="" />
        </div>
        <div class="memo memoContent">
          <p>备忘录内容</p>
          <input type="text" v-model="selectedTable.content" name="" id="" />
        </div>
        <div class="memo memoData">
          <p>提醒时间</p>
          <c-date-picker-vue @select="selectedDate"></c-date-picker-vue>
        </div>
      </div>
    </c-dialog>

    <!--  编辑备忘录 -->
    <c-dialog v-model="showEditMemo" title="编辑备忘录" @confirm="editMemo" :mask="true">
      <div class="addMemo">
        <div class="memo memoTitle">
          <p>备忘录名称</p>
          <input type="text" v-model="selectedTable.title" id="" />
        </div>
        <div class="memo memoContent">
          <p>备忘录内容</p>
          <input type="text" v-model="selectedTable.content" id="" />
        </div>
        <div class="memo memoData">
          <p>提醒时间</p>
          <!-- <el-date-picker v-model="datePicker" type="date" placeholder="选择日期"> </el-date-picker> -->
          <c-date-picker-vue @select="selectedDate"></c-date-picker-vue>
        </div>
      </div>
    </c-dialog>

    <!-- 分页 -->
    <div class="page">
      <c-pagination @change="changePage" :pageSize="memoParam.limit" :total="contactsTotal"></c-pagination>
    </div>
  </div>
</template>

<script>
import CDialog from '@/components/c-dialog'
import cDatePickerVue from '@/components/c-date-picker.vue'
import * as memoApi from '@/api/memo.js'
import _m from 'moment'
export default {
  name: 'Memo',
  components: {
    CDialog,
    cDatePickerVue
  },
  data() {
    return {
      showNewClient: false,
      showEditMemo: false,
      datePicker: '',
      contactsTotal: 0,
      // 列表数据
      memoData: [],
      selectedTable: {},
      // 审批状态
      approvalState: [
        {
          id: 1,
          name: '全部',
          isChecked: true
        },
        {
          id: 2,
          name: '已提醒',
          isChecked: false
        },
        {
          id: 3,
          name: '未提醒',
          isChecked: false
        }
      ],
      // 选择提醒时间
      selectedTime: '',
      memoParam: {
        search: '',
        status: '',
        starday: '',
        endday: '',
        page: 1,
        limit: 7
      }
    }
  },
  created() {
    this.getMemoList()
  },
  methods: {
    /**
     * 获取备忘录列表
     */
    getMemoList() {
      memoApi.getMemoDatalist(this.memoParam).then(res => {
        this.memoData = res.data.data
        this.contactsTotal = res.data.total
      })
    },
    /**
     * 关键字搜索
     */
    memoSearch(e) {
      this.memoParam.search = e.keyWord
      if (e.date) {
        this.memoParam.starday = e.date[0]
        this.memoParam.endday = e.date[1]
      } else {
        this.memoParam.starday = ''
        this.memoParam.endday = ''
      }
      this.getMemoList()
    },
    // 切换数据
    changeNavigation(i) {
      if (i.id == 1) {
        this.memoParam.status = ''
      } else if (i.id == 2) {
        this.memoParam.status = '1'
      } else if (i.id == 3) {
        this.memoParam.status = '0'
      }
      this.memoParam.page = 1
      this.getMemoList()
    },
    // 跟进消息
    follow(row) {
      const param = {
        id: row.id
      }
      memoApi.delMemo(param).then(res => {
        this._showMsg('删除成功')
        this.getMemoList()
      })
    },
    // 编辑备忘录
    checkEditMemo(row) {
      this.selectedTable = {
        title: row.title,
        content: row.content,
        startime: row.startime,
        id: row.id
      }
      this.showEditMemo = true
    },
    // 选中的项
    checkedClient(row) {
      this.selectedTable = row
    },
    openAddMemo() {
      this.selectedTable = {
        title: '',
        content: '',
        createtime: '',
        startime: ''
      }
      this.showNewClient = true
    },
    //新增备忘录
    addMemo() {
      this.selectedTable.createtime = _m().format('YYYY-MM-DD HH:mm:ss')
      memoApi.addMemo(this.selectedTable).then(res => {
        if (res.code == 1) {
          this._showMsg('添加成功')
          this.getMemoList()
          this.showNewClient = false
        }
      })
    },
    // 选择时间
    selectedDate(date) {
      this.selectedTable.startime = date
    },
    editMemo() {
      memoApi.editMemo(this.selectedTable).then(res => {
        this._showMsg('编辑成功')
        this.getMemoList()
        this.showEditMemo = false
      })
    },
    /**
     * 跳转页数
     */
    changePage(i) {
      this.memoParam.page = i
      this.getMemoList()
    }
  }
}
</script>

<style lang="scss" scoped>
.memo-page {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  background-color: #fff;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
    background-color: #f1f1f1;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
  }
}

.btn-group {
  .courtDate {
    background: linear-gradient(230deg, #fc9940, #f37902);
  }
  // .frontDesk {
  //   background: linear-gradient(230deg, #2fca70, #05c154);
  // }
}

.table-operate {
  display: flex;
  align-items: center;
  justify-content: center;
  .edit {
    color: #179d92;
    margin-right: 8px;
    cursor: pointer;
  }
  .follow {
    cursor: pointer;
  }
  .active {
    color: #f90e45;
  }
}

.addMemo {
  padding-right: 1.3rem;
  .memo {
    display: grid;
    grid-template-columns: 7.45rem auto;
    align-items: center;
    margin-bottom: 1.04rem;
    text-align: right;
    p {
      margin-right: 0.78rem;
    }
    input {
      width: 20.83rem;
      height: 1.98rem;
      padding: 0 0.5rem;
      border: 1px solid #cccccc;
      border-radius: 0.21rem;
    }
  }

  .memoData {
    display: grid;
    align-items: flex-start;
    margin-top: 1.51rem;
    margin-bottom: 2.71rem;
  }
}

.uplode {
  padding: 20px;
}
</style>
