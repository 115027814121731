import request from './request'

export function getMemoDatalist(data) {
  return request({
    url: '/memo/datalist',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data
  })
}

export function editMemo(data) {
  return request({
    url: 'memo/edit',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data
  })
}

export function addMemo(data) {
  return request({
    url: 'memo/add',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data
  })
}

export function delMemo(data) {
  return request({
    url: '/memo/del',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data
  })
}
